.searchbox > div .el-input {
  flex: 1;
}
.searchboxItem {
  display: flex;
  align-items: center;
}
.searchboxItem .itemLabel {
  min-width: 6rem;
  text-align: right;
  font-size: 14px;
  padding: 0 0.5rem;
}
